/*  */
.dropdown-temp {
    position: relative;
    display: inline-block;
    float: right;
  }
  .dropdown-temp .prof-icon-list{
    position: relative;
    /* display: inline-block; */
    display: flex;
    /* float: right; */
  }
  .prof-icon-list .profile-icon:hover{
    cursor: pointer;
  }
  .prof-icon-list .notify-icon:hover{
    cursor:pointer
  }
  
  .dropdown-content-temp {
    /* display: none; */
    position: absolute;
    background-color: #d2d5e9;
    min-width: 250px;
    /* overflow: auto; */
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 111111;
    right:10px;
    border-radius: 8px;
  }
  .dropdown-content-temp .profile-content{
      height: 40px;
      border-bottom: 1px solid #adadad;
      z-index: 1111 !important;
      background-color: #435ebe;
    }
  .dropdown-content-temp  h6{
      width: 250px;
      text-align: center !important;
      padding-top: 10px;
      color:#ffffff;
    }
  .dropdown-content-temp  h6 b{
      font-size: 16px;
      font-weight: 700;
    }
  .dropdown-content-temp a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-temp a:hover {background-color: #9694ff;color:#fff}
  
  .show {display: block;}

  .MuiTableCell-root {
    padding: 5px 5px !important;
    font-family: var(--bs-body-font-family) !important;
  }
  .MuiTableCell-paddingCheckbox:last-child{
    width:5% !important;
  }
  .multiTable-custom{
    background-color:#96979b;
    color: #9899ac;
  }
  
  .report_download
  {
    /* border:1px solid #adadad; */
    width: 30px;
    height:30px;
    right: 55px;
    /* position: absolute; */
    text-align: center;
    margin-top: 7px;
    margin-left: 10px;
  }
  .report_download a
  {
    padding: 6px 12px;
    background-color: #0d6efd;
    color: #fff;
    border-radius: 4px;
    vertical-align: middle;
  }
  .pr-25{
    padding-right: 25px;
  }
  .report-device{
    width: 125px;
    height: 33px;
    margin-left: 10px;
    padding-left: 5px;
    border: 1px solid #d7cfcf;
    border-radius: 4px;
  }
  .fromDate, .toDate{
    height:33px;
    margin-left:10px;
    padding-left:5px;
    border: 1px solid #d7cfcf;
    border-radius:4px;
  }
  .card-icon{
    font-size: xx-large;
    color: #5e46dd;
  }
  .color-ping{
    color: #FF00FF;
    /* color: #918E85; */
  }
  .color-lavender{
    color: #2A2AD1;
  }
  .color-digital{
    color: #e98c4a;
  }
  .color-user-blocked{
    color: #607080;
  }
  .color-user-unverified{
    color: #f00000;
  }
  .color-user-verified{
    color: #008000;
  }
  .color-user-total{
    color: #0d6efd;
  }

  .profile-notifications{
    font-size: 22px;
    color: #435ebe;
  }
  .user-profile{
    font-size: 22px;
    color: #435ebe;
  }
  .mt-20{
    margin-top: 20px;
  }
  .error-msg{
    color: #f00000;
  }
  .sales-total-content{
    width:95%;
    margin:0 auto;
    border:1px solid #DCE6EC;
    background-color: #fff;
    border-radius: 4px;
  }
  .report-device-table{
    width: 100%;
    border: 1px solid #d7cfcf;
    border-radius: 4px;
  }
  .vv-color-code{
    color:#6c757d;
  }
  .copy-right{
    bottom: 10px;
    position: fixed;
    left: 4%;
  }
  .MuiIconButton-root {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 2px 12px !important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}
.popup-content-sec{
  height:400px;
  overflow-y: scroll;
}
.checkbox{
  display: flex;
  align-items: center;
}
.w-200{
  display: inline-block;
  width: 200px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.customer-name{
  /* font-weight: 700;
  border: 1px solid #adadad;
  padding: 5px 10px;
  border-radius: 4px;
  color: #435ebe; */
  font-weight: 600;
  color: #607080;
  text-align: center;
}

.card-status-up{
  color: green;
  font-weight: 700;
  font-size: 20px;
}
.card-status-down{
  color: red;
  font-weight: 700;
  font-size: 20px;
}
.refresh-sec{
  margin-bottom: 5px; 
  width: 100%; 
  display: inline-block;
}
/* .refresh-btn{
  width: 30px;
  height: 30px;
  background-color: #435ebe;
  border-color: #435ebe;
  color: #fff;
  text-align: center;
  line-height: 35px;
  float: right;
  cursor: pointer;
  border-radius: 5px;
} */
.ml-25{
  margin-left: 25px;
}

/*cc reports*/
.is-disabled
{
  pointer-events: none;
  opacity: 0.5;
}
.hoder-name-input{
    width: 115px;
    height: 30px;
    border: 1px solid #d7cfcf;
    border-radius: 4px;
}
.cc-reports-filter{
  width:100%;
  display: inline-block;
  margin: 5px 0px 10px 0px;
}
.filter-row-1{
  width: 33%;
  display:inline-block;  
}
.date-range-filter-row{
  width: 33%;
  display:inline-block;
  
}
.button-filter{
  min-width: 33%;
  display: inline-block;
}
.cc-report_download
  {
    width: 30px;
    height: 30px;
    text-align: center;
    margin-left: 20px;
  }
  .cc-report_download a
  {
    padding: 5px 10px;
    background-color: #0d6efd;
    color: #fff;
    border:1px solid transparent;
    border-radius: 4px;
  }
  .cc-fromDate, .cc-toDate{
    width: 215px;
    height:33px;
    margin-left:10px;
    padding-left:5px;
    border: 1px solid #d7cfcf;
    border-radius:4px;
  }
  .cc-summary-cbx{
    margin-left: 10px;
    vertical-align: middle;
  }
  .cc-report-device{
    width: 60%;
    height: 33px;
    margin-left: 10px;
    padding-left: 5px;
    border: 1px solid #d7cfcf;
    border-radius: 4px;
  }
  .import-excel-th, .import-excel-td {
    border:1px solid black;
    padding: 3px;
  }
  .import-excel-td {
    border:1px solid black;
    padding: 3px;
  }
  .mr-8{
    margin-right:10px;
  }

  .modal-dialog {
    max-width: 850px;
    margin: 2rem auto;
}
.common-row{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.common-row .limit,.refresh{
    margin:5px 8px;
    width: 30px;
    height: 30px;
    background-color: #435ebe;
    border-color: #435ebe;
    color: #fff;
    text-align: center;
    line-height: 35px;
    /* float: right; */
    cursor: pointer;
    border-radius: 5px;
  }

  .download{
    margin:5px 10px;
    width: auto;
    cursor: pointer;
    border-radius: 5px;
  }
  .download :hover{
    cursor: pointer;
  }
  .download select{
    height: 30px;
    border-radius: 4px;
  }
  .download span{
    padding-right:10px;
    font-weight: 400;
  }
  .required-field{
    color:#fc1111;
  }
  .contactless-card-sec{
    display: inline-block;
  }
  
  .contactless-card{
    
      width: 45px;
    height: 17px;
    vertical-align: middle;
    
  }

  .invalid-user-msg{
    margin-bottom: 25px;
    text-align: center;
    font-size: x-large;
    font-weight: 600;
  }
  
  .invalid-user-button{    
    width:90%;
  }

  .top-five{
    height:325px;
    overflow-y: scroll;
  }

  .device-btn-sec{
    display: flex;
    padding: 10px 5px;
    flex-direction: row-reverse;
    & Button{
      width:auto;
      padding-right: 10px;
    }
  }
  .device-btn-sec .Btn{
    width:100px;
    margin-right: 10px;
  }