.table-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

table {
  border-collapse: collapse;
  /* margin: 25px 0; */
  margin: 0 auto;
  font-size: 14px;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  /* width: 720px */
  width:100%;
}

table thead tr {
  background-color: #4bbd7f;
  color: #ffffff;
  text-align: left;
}

table th,
table td {
    padding: 10px 15px;
}

table tbody tr {
  border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-of-type(even) {
  background-color: #f6f6f6;
}

table tbody tr:last-of-type {
  border-bottom: 2px solid #4bbd7f;
}

table tbody tr.active-row {
  font-weight: bold;
  color: #4bbd7f;
}

table td:first-child input {
  width: 50px;
}

table td:nth-child(2) input {
  width: 120px;
}

table .edit-cell-container button {
  border-radius: 50px;
  height: 26px;
  width: 26px;
  border: 1px solid #ccc;
}

table .footer-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
}

table .add-button {
  color: #4bbd7f;
}

table .remove-button {
  color: #e44747;
}

table .footer-buttons button {
  border: none;
  background-color: transparent;
}

table .edit-cell-action button[name='edit'] {
  color: #ffb918;
}

table .edit-cell-action button[name='cancel'] {
  color: #7b7b7b;
}

table .edit-cell-action button[name='done'] {
  color: #4bbd7f;
}

table .edit-cell-action button[name='done']:disabled {
  color: #ccc;
}

table .edit-cell-action button[name='remove'] {
  color: red;
  background-color: rgb(230, 208, 208);
}

table input, select {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
}

table .edit-cell-action {
  display: flex;
    gap: 5px;
}

table .edit-cell-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 4px;
}

table input[type='checkbox'] {
  width: 16px;
  height: 16px;
}

select:invalid, input:invalid {
  border: 2px solid red;
}

.cancel {
  cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid gray;
    padding: 3px 5px;
    border-radius: 4px;
    color: red;
}

.device-config-success{
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid gray;
  padding: 3px 5px;
  border-radius: 4px;
  color: green;
}

.device-config-edit{
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid gray;
  padding: 3px 5px;
  border-radius: 4px;
  color: gray;
}
.remove{
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid gray;
  padding: 3px 5px;
  border-radius: 4px;
  color: red;
  /* margin-left: 5px; */
}
.validationMsg-total{
  color:red;
}
.config-update-icon{
  width:25px;
  height:25px;
  padding: 3px;
  border: 1px solid gray;
  cursor: pointer;
}
.config-Add-icon{
  cursor: pointer;
}
.config-save-icon{
  cursor: pointer;
  padding: 3px;
  border: 1px solid #4bbd7f !important;
  color:#4bbd7f !important;
  border-radius: 3px;
  
}
.config-save-icon:hover{
  background-color:#278ae9  !important;
  color: #fff !important;
}
.config-reset-icon{
  cursor: pointer;
  padding: 5px;
  color:#278ae9 !important;
  border: 1px solid #4bbd7f !important;
  /* color:#ffffff; */
  border-radius: 3px;
  margin-left: 20px;
}
.config-reset-icon:hover{
  border: 1px solid #4bbd7f !important;
  color:#ffffff !important;
  background-color: #278ae9 !important;
}
.update-layout{
  color: #278ae9;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
}

.config-download-icon{
  cursor: pointer;
  padding: 0px 10px;
  vertical-align: top;
  color:#278ae9 !important;
  border: 1px solid #4bbd7f !important;
  /* color:#ffffff; */
  border-radius: 3px;
  margin-left: 20px;
}
.config-download-icon .bi-download{
  font-size: 20px !important;
  vertical-align: -webkit-baseline-middle !important;
}
.config-download-icon:hover{
  color:#ffffff !important;
  background-color:#278ae9 !important;
  border: 1px solid #4bbd7f !important;
}
.email-part{
  display: inline-flex;
  margin-bottom: 5px;
}
.email-remove{
  border-radius: 4px;
  padding: 4px 3px 0px 4px;
  color: #ff0000;
  /* background: #ff0000; */
  margin-left: 10px;
  /* border: 1px solid #ff0000; */
  cursor: pointer;
  height: 30px;
  line-height: 20px;
  font-weight: bold;
}
.email-add{
  border-radius: 4px;
  padding: 0px 8px 0px 8px;
  color: #008000;
  /* background: #ff0000; */
  margin-left: 10px;
  border: 1px solid #008000;
  cursor: pointer;
  font-weight: 800;
}
.email-label{
  margin-bottom: 5px;
}
.padding-5{
  padding: 4px 0px 4px 0px;
  vertical-align: text-bottom;
}